<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">Gift Cards</h5>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" md="6" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet/giftcards"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        BUY GIFT CARD
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" md="6" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet/findcard"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        CHECK GIFT CARD BALANCE.
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "GiftCards",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
